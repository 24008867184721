import React from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"

interface Props {
  pageName?: string;
}

export const Footer:React.FC<Props> = ({ pageName }) => {

  const footerClass = `footer${pageName === "お問い合わせ" ? " footer-contact" : ""}`

  return(
    <footer className={footerClass}>
      <Container>
        <Row>
          <Col xs={12} className="footer-nav">
            <ul className="nav-list">
              <li className="nav-item">
              <Link className="nav-link" activeClassName="active" to="/">
                  ホーム
                </Link>
              </li>
              <li className="nav-item">
              <Link className="nav-link" activeClassName="active" to="/works/">
                  業務案内
                </Link>
              </li>
              <li className="nav-item">
              <Link className="nav-link" activeClassName="active" to="/flow/">
                  工事の流れ
                </Link>
              </li>
              <li className="nav-item">
              <Link className="nav-link" activeClassName="active" to="/company/">
                  会社概要
                </Link>
              </li>
              <li className="nav-item">
              <Link className="nav-link" activeClassName="active" to="/contact/">
                  お問い合わせ
                </Link>
              </li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col className="copyright">
            <small>&copy; 2018 メープル・シーリング</small>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
