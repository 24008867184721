import React, { useState , useEffect } from "react"
import { Link } from "gatsby"
import AnchorLink from 'react-anchor-link-smooth-scroll';
interface Props {
  showBelow: number;
}

export const Info: React.FC<Props> = ({ showBelow }) => {
  const [show, setShow] = useState(showBelow ? false : true)

  const handleScroll = () => {
    if (window.pageYOffset > showBelow) {
      if (!show) setShow(true)
    } else {
      if (show) setShow(false)
    }
  }

  useEffect(() => {
    if (showBelow) {
      window.addEventListener(`scroll`, handleScroll)
      return () => window.removeEventListener(`scroll`, handleScroll)
    }
  })

  return(
    <>
      <div id="info" className={`info ${show ? "is-show" : [null]}`}>
        <div className="sp-conversion">
          <ul className="sp-conversion-list">
            <li className="btn-conversion-tel">
              <a href="tel:090-1295-8300" aria-label="電話お問い合わせ">
                お問い合わせ
              </a>
            </li>
            <li className="btn-conversion-mail">
              <Link to="/contact#contactform" aria-label="メールお問い合わせ">
                お問い合わせ
              </Link>
            </li>
          </ul>
        </div>
        <div id="btn-top" className="btn-top">
          <AnchorLink href="#header-top" aria-label="トップへ戻る"></AnchorLink>
        </div>
      </div>
    </>
  )
}
