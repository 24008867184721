import React from "react"

interface Props {
  pageName?: string;
}

export const Hero: React.FC<Props> = props => {
  const { pageName } = props
  return (
    <section className="hero">
      <h2 className="hero-title">{pageName}</h2>
    </section>
  )
}
