import React from "react"
import Fade from "react-reveal/Fade"
import AnchorLink from 'react-anchor-link-smooth-scroll';

import imgCatch from "../images/top-hero-catch.svg"

interface Props {
  heroHeight: number;
}


export const TopHero: React.FC<Props> = (props) => {
  const {heroHeight} = props;
  return (
    <section id="topHero" className="top-hero" style={{height: heroHeight}}>
    <div className="inner">
      <Fade duration={1500} delay={200}>
        <h2 className="top-hero-catch">
          <img
            className="top-hero-catch-img"
            src={imgCatch}
            alt="暮らしを守る確かな技術。"
          />
        </h2>
      </Fade>
      <Fade top duration={700} delay={1300} distance="40px">
        <div className="btn-scroll">
          <AnchorLink
            className="btn-scroll-anchor"
            href="#intro"
            aria-label="コンテンツへ移動"
          >
            <span></span>
            <span></span>
            <span></span>Scroll
          </AnchorLink>
        </div>
      </Fade>
    </div>
  </section>
  )
}
