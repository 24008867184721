import React, { useRef, useEffect } from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Container, Row, Col } from "react-bootstrap"

import imgLogo from "../images/header-logo.svg"
import imgMail from "../images/header-mail.svg"
import imgTel from "../images/header-tel.svg"

interface Props {
  setHeaderHeight: React.Dispatch<React.SetStateAction<number>>;
  pageName: string;
}

export const Header: React.FC<Props> = props => {
  const headerRef: any = useRef()
  const { setHeaderHeight, pageName } = props

  useEffect(() => {
    if (pageName === "TOP") {
      const resizeObserver = new ResizeObserver(entries => {
        const newHeaderHeight = entries[0].contentRect.height
        setHeaderHeight(newHeaderHeight)
      })
      headerRef.current && resizeObserver.observe(headerRef.current)
      return () => {
        resizeObserver.disconnect()
      }
    }
  }, [pageName, setHeaderHeight])

  const topText = () => {
    const resultText = pageName !== "TOP" ? `${pageName} | ` : false
    return (
      <div id="header-top" className="header-top">
        <Container>
          <h1 className="header-top-text">
            {resultText}
            富士・富士宮市の防水工事・雨漏り補修なら
            <br />
            メープル・シーリングにおまかせください！
          </h1>
        </Container>
      </div>
    )
  }

  return (
    <header id="header" className="header" ref={headerRef}>
      {topText()}
      <Container>
        <Row>
          <Col md={5} className="header-logo">
            <Link to="/">
              <img
                className="header-logo-img"
                src={imgLogo}
                alt="シーリング・防水工事,メープル・シーリング"
              />
            </Link>
          </Col>
          <Col md={7} className="header-contact mt-4">
            <div className="header-contact-mail">
              <Link to="/contact/">
                <img
                  className="btn-effect"
                  src={imgMail}
                  alt="ご相談・お見積り無料"
                />
              </Link>
            </div>
            <div className="header-contact-tel">
              <img
                className="btn-effect"
                src={imgTel}
                alt="【営業時間】8:00～18:00 【定休日】日曜日"
              />
            </div>
          </Col>
        </Row>
      </Container>
      <nav className="header-nav navber">
        <div className="inner">
          <ul className="navber-nav">
            <li className="nav-item">
              <Link className="nav-link" activeClassName="active" to="/">
                <StaticImage
                  src="../images/header-icon-home.png"
                  alt="ホーム"
                  className="nav-icon"
                />
                <p className="nav-menu">ホーム</p>
                <span className="nav-subtext">HOME</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" activeClassName="active" to="/works/">
                <StaticImage
                  src="../images/header-icon-works.png"
                  alt="業務案内"
                  className="nav-icon"
                />
                <p className="nav-menu">業務案内</p>
                <span className="nav-subtext">WORKS</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" activeClassName="active" to="/flow/">
                <StaticImage
                  src="../images/header-icon-flow.png"
                  alt="工事の流れ"
                  className="nav-icon"
                />
                <p className="nav-menu">工事の流れ</p>
                <span className="nav-subtext">FLOW</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                activeClassName="active"
                to="/company/"
              >
                <StaticImage
                  src="../images/header-icon-company.png"
                  alt="会社概要"
                  className="nav-icon"
                />
                <p className="nav-menu">会社概要</p>
                <span className="nav-subtext">COMPANY</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                activeClassName="active"
                to="/contact/"
              >
                <StaticImage
                  src="../images/header-icon-contact.png"
                  alt="お問い合わせ"
                  className="nav-icon"
                />
                <p className="nav-menu">お問い合わせ</p>
                <span className="nav-subtext">CONTACT</span>
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  )
}
// Header.propTypes = {
//   siteTitle: PropTypes.string,
// }

// Header.defaultProps = {
//   siteTitle: ``,
// }
