import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Header } from "./Header"
import { TopHero } from "./TopHero"
import { Hero } from "./Hero"
import { Inquiry } from "./Inquiry"
import { Info } from "./Info"
import { Footer } from "./Footer"

interface Props {
  setHeaderHeight?: React.Dispatch<React.SetStateAction<number>>;
  children: any;
  pageName: string;
}

export const Layout: React.FC<Props> =  ({ children , pageName }) => {

  const [ viewHeight, setViewHeight ] = useState(0);
  const [ headerHeight , setHeaderHeight ] = useState(0);
  const heroHeight = viewHeight - headerHeight;

  useEffect(() => {
    if (pageName === "TOP") {
      const userAgent = navigator.userAgent;
      const handleResize = () => {
        const newHeight = window.innerHeight;
        setViewHeight(newHeight);
      }
      handleResize();
      if (userAgent.indexOf("iPhone") >= 0 || userAgent.indexOf("iPad") >= 0 || userAgent.indexOf("Android") >= 0) { window.addEventListener("orientationchange", handleResize)
      } else {
        window.addEventListener("resize", handleResize);
      }
      return () => {
        if (userAgent.indexOf("iPhone") >= 0 || userAgent.indexOf("iPad") >= 0 || userAgent.indexOf("Android") >= 0) { window.removeEventListener("orientationchange", handleResize)
        } else {
          window.removeEventListener("resize", handleResize);
        }
      }
    }
  }, [pageName]);

  const displayHero = () => {
    if (pageName === "TOP") {
      return <TopHero heroHeight={heroHeight}/>;
    }
    return <Hero pageName={pageName}/>;
  }

  const displayInquiry = () => {
    if (pageName === "お問い合わせ") {
      return
    }
    return (
      <>
        <Inquiry />
        <Info showBelow={250}/>
      </>
    )
  }

  return (
    <>
      <Header setHeaderHeight={setHeaderHeight} pageName={pageName}/>
      <main>
        {displayHero()}
        {children}
        {displayInquiry()}
      </main>
      <Footer pageName={pageName}/>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
