import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Container, Row, Col } from "react-bootstrap"

export const Inquiry: React.FC = () => (
  <section className="inquiry mt-5">
    <Container className="contents-box fade_in">
      <h2 className="inquiry-title">お気軽にご相談ください</h2>
      <p className="inquiry-text">
        防水・雨漏りなど気になることがございましたら
        <br className="d-md-none" />
        当社にお任せください！&emsp;無料で調査致します！
      </p>
      <Row>
        <Col md={6} className="mt-5">
          <StaticImage
            src="../images/footer-logo.png"
            alt="シーリング・防水工事 メープル・シーリング"
            className="inquiry-address"
          />
          <address className="inquiry-text mt-4 mb-3">
            〒418-0022&emsp;静岡県富士宮市小泉2385-3
          </address>
          <address className="inquiry-text my-0">
            TEL・FAX：&nbsp;0544-24-5474
          </address>
        </Col>
        <Col md={6} className="mt-5">
          <StaticImage
            src="../images/footer-tel.png"
            alt="代表直通TEL"
            className="inquiry-call"
          />
          <Link to="/contact/" aria-label="メールでのお問い合わせはこちら">
            <StaticImage
              src="../images/footer-mail.png"
              alt="メールでのお問い合わせはこちら"
              className="inquiry-call btn-effect mt-4"
            />
          </Link>
        </Col>
      </Row>
    </Container>
  </section>
)
